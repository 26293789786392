@import '_mixins/pux/breakpoints';
@import '_mixins/pux/css-rules';
@import '_variables';
@import "helpers";

.agrotec-grid {

  &-item {
    float: left;
    margin: 0;
    padding: 0;
  }

  &-6col {

    .agrotec-grid-item {
      width: 100%;

      @include media(md) {
        width: 33.33333333%;
      };

      @include media(lg) {
        width: 25%;
      }

      @include media(xxl) {
        width: 16.6666666%;
      }

    }
  }

  &-5col {

    .agrotec-grid-item {
      width: 100%;

      @include media(sm) {
        width: 50%;
      };

      @include media(md) {
        width: 33.33333333%;
      };

      @include media(lg) {
        width: 25%;
      }

      @include media(xxl) {
        width: 20%;
      }

    }
  }

  &-4col {

    .agrotec-grid-item {
      width: 100%;

      @include media(md) {
        width: 50%;
      };

      @include media(xxl) {
        width: 25%;
      }
    }
  }

  &-3col {

    .agrotec-grid-item {
      width: 100%;

      @include media(md) {
        width: 50%;
      };

      @include media(xl) {
        width: 33.33333333333%;
      }
    }
  }

  &:after {
    content: "";
    display: table;
    clear: both;
  }
}
