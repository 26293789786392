@import '_mixins/pux/breakpoints';
@import '_mixins/pux/css-rules';
@import '_variables';
@import "helpers";

body, html {
  color: $base-color;
  font-family: $base-font;
  height: 100%;
  font-size: $font-mobile;

  @include media(md) {
    font-size: $font-desktop;
  };
}

body > form,
#main-content {
  min-height: 100%;
}

#main-content {
  position: relative;
}

img {
  max-width: 100%;
  height: auto !important;
}

iframe {
  max-width: 100% !important;
}

hr {
  display: none;
}

a {
  color: $light-blue;

  &:hover, &:focus {
    color: $light-blue;
  }
}

h1, .h1 {
  font-weight: 700;
  font-size: 34px;

  @include media(md) {
    font-size: 66px;
  };
}

h2, .h2 {
  font-size: 28px;
  font-weight: 700;

  @include media(md) {
    font-size: 36px;
  };
}

h3, .h3 {
  font-size: 26px;
}

.agrotec-container {
  max-width: 1600px;
  margin: 0 auto;
  padding: 0 15px;

  @include media(md) {
    padding: 0 30px;
  };
}

.full-width {
  width: 100%;

  &-padding {
    padding: 20px 0;

    @include media(md) {
      padding: 40px 0;
    };
  }

  &-padding-top {
    padding-top: 20px;

    @include media(md) {
      padding-top: 40px;
    };
  }

  &-padding-bottom {
    padding-bottom: 20px;

    @include media(md) {
      padding-bottom: 40px;
    };
  }

  &-white, &-white &-grey, &-white &-dark {
    background: $base-white;
    color: $base-color;
  }

  &-grey, &-grey &-dark, &-grey &-white {
    background: $bg-slide;
    color: $base-color;
  }

  &-dark, &-dark &-grey,  &-dark &-white {
    background: $base-color;
    color: $base-white;
  }

  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

.btn {
  display: inline-block;
  border-radius: 0;
  @include transition(150ms);

  &.btn-primary {
    font-size: 17px;
    font-weight: 700;
    padding: 15px 40px;
    background: $light-blue;
    color: $base-white;
    border: 0;
    box-shadow: none;
    outline: 0;

    &:hover, &:focus {
      background: $base-color;
      border: 0;
      box-shadow: none;
      outline: 0;
    }
  }
}

.btn-wrap {
  display: inline-block;
  position: relative;
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

.btn-wrap.border-strict .btn{
  border-radius: 0;
}

.btn-wrap.border-rounded .btn{
  border-radius: 5px;
}

.btn-wrap.border-super-rounded .btn{
  border-radius: 30px;
}

.btn-wrap.color-blue .btn{
  background: $light-blue;
  color: $base-white;

  &:hover, &:focus {
    background: $base-color;
  }
}

.btn-wrap.color-dark .btn{
  background: $base-color;
  color: $base-white;

  &:hover, &:focus {
    background: $light-blue;
  }
}

.btn-wrap.color-white .btn{
  color: $base-color;
  background: $base-white;

  &:hover, &:focus {
    background: $base-color;
    color: $base-white;
  }
}

.btn-wrap.color-grey .btn{
  background: $bg-slide;
  color: $base-color;

  &:hover, &:focus {
    background: $base-color;
    color: $bg-slide;
  }
}

.btn-wrap.color-transparent .btn{
  border: 1px solid $base-color;
  color: $base-color;
  background: transparent;

  &:hover, &:focus {
    background: $base-color;
    color: $base-white;
  }
}

.text-block {
  /*padding: 40px 0;

  @include media(md) {
    padding: 90px 0;
  };*/

  h1 {
    padding: 0 0 30px;
    margin: 0;

    @include media(md) {
      padding: 0 0 70px;
      margin: 0;
    };
  }

  &-light {
    font-weight: 300;
  }
}

.arrow-links a, a.arrow-link {
  color: $light-blue;
  display: block;
  margin-bottom: 3px;
  position: relative;
  padding-left: 30px;
  @include transition(200ms);

  &:before {
    content: "\e902";
    width: 14px;
    height: 14px;
    position: absolute;
    left: 0;
    top: 9px;
    display: inline-block;
    font-size: 14px;

    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  &:hover, &:focus {
    color: $dark-blue;
    text-decoration: none;
  }

}

.breadcrumbs {
  position: absolute;
  font-size: 13px;
  margin-top: 15px;
  width: 100%;
  z-index: 10;

  &-separator {
    margin: 0 5px;
  }

  a {
    @include transition(150ms);
  }

  a:hover {
    color: $base-color;
    text-decoration: none;
  }

  &-light {
    span {
      color: $base-white;
    }

    a:hover {
      color: $base-white;
      text-decoration: none;
    }
  }


}

input[type="radio"],
input[type="checkbox"] {
  display: none;
}

.narrow-form-wrapper {

  input[type="checkbox"] + label,
  input[type="radio"] + label {
    position: relative;
    padding-left: 40px;
    cursor: pointer;
  }

  input[type="checkbox"] + label:before,
  input[type="radio"] + label:before {
    position: absolute;
    left: 0;
    top: 3px;
    content: "";
    display: inline-block;
    width: 20px;
    height: 20px;
    background: $bg-megamenu;

    @include media(md) {
      top: 7px;
    };
  }

  input[type="checkbox"] + label:after,
  input[type="radio"] + label:after {
    content: "";
    width: 12px;
    height: 12px;
    background: $light-blue;
    position: absolute;
    display: inline-block;
    left: 4px;
    top: 7px;
    @include transition(200ms);
    opacity: 0;

    @include media(md) {
      top: 11px;
    };
  }

  input[type="checkbox"]:checked + label:after,
  input[type="radio"]:checked + label:after {
    opacity: 1;
  }

  input[type="radio"] + label:before,
  input[type="radio"] + label:after {
    border-radius: 50%;
  }

  textarea {

  }
}

.b-lazy {
   @include transition(500ms);
              opacity: 0;
  transform: scale(0.98);
}
.b-lazy.b-loaded {
              opacity: 1;
              transform: scale(1.0);
}

.show-static {

}

.show-left {
  opacity: 0;
  left: -10px;
  -webkit-transition: all ease-out 500ms;
  -moz-transition: all ease-out 500ms;
  -ms-transition: all ease-out 500ms;
  transition: all ease-out 500ms;

  &.show {
    opacity: 1;
    left: 0;
  }
}

.show-right {
  opacity: 0;
  left: 10px;
  -webkit-transition: all ease-out 500ms;
  -moz-transition: all ease-out 500ms;
  -ms-transition: all ease-out 500ms;
  transition: all ease-out 500ms;

  &.show {
    opacity: 1;
    left: 0;
  }
}

.show-fade {
  opacity: 0;
  -webkit-transition: opacity ease-out 500ms;
  -moz-transition: opacity ease-out 500ms;
  -ms-transition: opacity ease-out 500ms;
  transition: opacity ease-out 500ms;

  &.show {
    opacity: 1;
  }
}

.arrow-scroll {
  width: 40px;
  height: 40px;
  display: inline-block;
  background: $bg-slide;
  position: absolute;
  left: 50%;
  margin-left: -20px;
  margin-top: -40px;
  cursor: pointer;
  color: $light-blue;
  @include transition(150ms);

  &:before {
    content: "\e902";
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 14px;
    height: 14px;
    font-size: 14px;
    position: absolute;
    left: 13px;
    top: 13px;
    display: inline-block;
    @include rotate(90deg);
  }

  &:hover {
    color: $bg-slide;
    background: $light-blue;
  }
}

.border-bottom {
  border-bottom: 1px solid $border-grey;
}

.padding-top-40 {
  padding-top: 40px;
}

.webcam-container img {
  display: none;
}

.spinner-wrap {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(29,29,29,0.7);
  z-index: 9999;
}

.sk-cube-grid {
  width: 40px;
  height: 40px;
  position: absolute;
  left: 50%;
  margin-left: -20px;
  top: 50%;
  margin-top: -20px;
}

.sk-cube-grid .sk-cube {
  width: 33%;
  height: 33%;
  background-color: $light-blue;
  float: left;
  -webkit-animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
          animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
}
.sk-cube-grid .sk-cube1 {
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s; }
.sk-cube-grid .sk-cube2 {
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s; }
.sk-cube-grid .sk-cube3 {
  -webkit-animation-delay: 0.4s;
          animation-delay: 0.4s; }
.sk-cube-grid .sk-cube4 {
  -webkit-animation-delay: 0.1s;
          animation-delay: 0.1s; }
.sk-cube-grid .sk-cube5 {
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s; }
.sk-cube-grid .sk-cube6 {
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s; }
.sk-cube-grid .sk-cube7 {
  -webkit-animation-delay: 0s;
          animation-delay: 0s; }
.sk-cube-grid .sk-cube8 {
  -webkit-animation-delay: 0.1s;
          animation-delay: 0.1s; }
.sk-cube-grid .sk-cube9 {
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s; }

@-webkit-keyframes sk-cubeGridScaleDelay {
  0%, 70%, 100% {
    -webkit-transform: scale3D(1, 1, 1);
            transform: scale3D(1, 1, 1);
  } 35% {
    -webkit-transform: scale3D(0, 0, 1);
            transform: scale3D(0, 0, 1);
  }
}

@keyframes sk-cubeGridScaleDelay {
  0%, 70%, 100% {
    -webkit-transform: scale3D(1, 1, 1);
            transform: scale3D(1, 1, 1);
  } 35% {
    -webkit-transform: scale3D(0, 0, 1);
            transform: scale3D(0, 0, 1);
  }
}

h3 {
  font-size: 22px;
  font-weight: bold;

  @include media(md) {
    font-size: 32px;
  };
}

h4 {
  font-size: 20px;
  font-weight: bold;

  @include media(md) {
    font-size: 28px;
  };
}

h5 {
  font-size: 18px;
  font-weight: bold;

  @include media(md) {
    font-size: 24px;
  };
}

h6 {
  font-size: 18px;
  font-weight: bold;

  @include media(md) {
    font-size: 22px;
  };
}
