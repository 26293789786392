@import '_mixins/pux/breakpoints';
@import '_mixins/pux/css-rules';
@import '_variables';
@import "helpers";

.EditMode .header,
.DesignMode .header {
  position: static !important
}

.header  {
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  background: $base-white;
  @include transition(250ms);
  transform: translateZ(0);
  z-index: 100;


  -webkit-box-shadow: 0px 1px 2px 1px rgba(0,0,0,0.15);
  -moz-box-shadow: 0px 1px 2px 1px rgba(0,0,0,0.15);
  box-shadow: 0px 1px 2px 1px rgba(0,0,0,0.15);

  &.scrolled {
    top: -70px;
  }

  &-top {
    border-bottom: 1px solid $border-grey;
    padding: 15px 0;
    height: 70px;

    &-right {
      float: right;

      >* {
        display: inline-block;
        float: left;
      }

      ul {
        list-style-type: none;
        margin: 0;
        padding: 2px 0 0 0;


        li {
          display: inline-block;
          margin-right: 50px;
        }

        a {
          color: $font-grey;
          @include transition(150ms);
          font-size: 14px;

          &:focus, &:hover {
            outline: 0;
            color: $base-color;
            text-decoration: none;
          }
        }
      }

      .search-box {
        position: relative;

        input[type="text"] {
          border: 2px solid $border-grey;
          width: 340px;
          padding: 0 10px 0 50px;
          font-size: 14px;
          height: 40px;
          border-radius: 0;
          @include transition(200ms);

          @media screen and (max-width: 700px) {
            width: 200px;
          }

          &:focus {
            outline: 0;
            box-shadow: none;
            border-color: $light-blue;
          }
        }

        label {
          display: none;
        }

        input[type="submit"] {
          background: url("../img/icon-search-22x23.png");
          background: url("../img/Search_Lupa.svg");
          background-repeat: no-repeat;
          background-size: 22px 23px !important;
          background-position: 50% 50%;
          height: 40px;
          width: 40px;
          border: 0;
          overflow: hidden;
          text-indent: 50px;
          position: absolute;
          top: 0;
          left: 5px;
          cursor: pointer;
        }

        @media screen and (max-width: 540px) {
          display: none;
        }

        margin-right: 10px;

        @include media(md) {
          margin-right: 0;
        };

      }

      .socials {
        display: none;

        @media all and (min-width: 1200px) {
          display: block;
          margin-left: 20px;

          a {
            display: inline-block;
            border-radius: 50%;
            background: $border-grey;
            height: 40px;
            width: 40px;
            overflow: hidden;
            text-indent: 50px;
            margin-left: 10px;
            @include transition(150ms);

            &:hover, &:focus {
              text-decoration: none;

              &::before {
                color: $border-grey;
              }
            }
          }

          .ico-linkedin:before,
          .ico-facebook:before,
          .ico-instagram:before,
          .ico-youtube:before {
            position: relative;
            font-size: 18px;
            @include transition(150ms);
          }

          .ico-linkedin {

            &:before {
              top: 3px;
              left: -38px;
              color: #007ab9;
            }

            &:hover {
              background: #007ab9;
            }
          }

          .ico-facebook {

            &:before {
              top: 5px;
              left: -39px;
              color: #3b5998;
            }

            &:hover {
              background: #3b5998;
            }
          }

          .ico-youtube {

            &:before {
              top: 6px;
              left: -39px;
              color: #b31217;
              font-size: 22px;
            }

            &:hover {
              background: #b31217;
            }
          }

          .ico-instagram {

            &:before {
              top: 6px;
              left: -39px;
              color: #8a3ab9;
              font-size: 22px;
            }

            &:hover {
              background: #8a3ab9;
            }
          }
        };
      }
    }
  }

  &-bottom {

    .main-nav {
      >ul {
        list-style-type: none;
        padding: 0;
        margin: 0;

        &:hover {
          li a:before {
            height: 0 !important;
          }
        }

        >li {
          display: inline-block;
          width: 16.6666666%;
          float: left;
          $item-height: 60px;

          &.active {
            a:before {
              height: $item-height;
            }
          }

          >a {
            display: inline-block;
            width: 100%;
            text-align: center;
            padding: 0 5px;
            font-size: 17px;
            color: $base-color;
            line-height: $item-height;
            position: relative;

            &:before {
              content: "";
              position: absolute;
              width: 100%;
              height: 0;
              background: $bg-megamenu;
              top: 0;
              left: 0;
              z-index: -1;
              @include transition(150ms);
              @include transition-timing-function(ease-out);
            }


          }

          &:hover, &:focus {
            a {
              text-decoration: none;
              color: $base-color;

              &:before {
                height: $item-height !important;
              }
            }
          }
        }
      }
    }
  }

  &-logo {
    display: inline-block;
    width: 131px;
    height: 40px;
    background: url(../img/logo-131x40.png);
    background: url(../img/logo.svg );
    background-size: 131px 40px !important;
    position: relative;
    top: 0;
    left: 0;
    @include transition(200ms);

    h1, span {
      display: none;
    }
  }
}

.search-helper {
  display: none;
  @media all and (min-width: 1680px) {
    display: block;
    background: url("../img/icon-search-22x23.png");
    background: url("../img/Search_Lupa.svg");
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: 22px 23px !important;
    height: 40px;
    width: 40px;
    border: 0;
    overflow: hidden;
    text-indent: 50px;
    top: 80px;
    right: -50px;
    cursor: pointer;
    position: absolute;
    @include transition(300ms);
  }
}

.logo-helper {
  display: none;
  @media all and (min-width: 1680px) {
    display: inline-block;
    width: 22px;
    height: 40px;
    background: url("../img/logo-131x40.png");
    background: url("../img/logo.svg ");
    background-size: 131px 40px !important;
    position: absolute;
    top: 90px;
    left: -60px;
    @include transition(300ms);
  }
}

.scrolled.header {
  @media all and (min-width: 1680px) {
    .logo-helper {
      left: 30px;
    }

    .search-helper {
      right: 20px;
    }
  };
}

.after-header {
  height: 130px;
  @include transition(200ms);



  &.scrolled {
    /*height: 60px;*/
  }

  &.mobile {
    height: 69px;
  }

}

.megamenu-wrapper {
  position: absolute;
  width: 100%;
  background: rgba(232,235,235, 0.4);
  left: 0;
  opacity: 0;
  pointer-events: none;
  transform-style: preserve-3d;
  @include transition(200ms);
  -webkit-transform: translateY(-20px);
  -moz-transform: translateY(-20px);
  -ms-transform: translateY(-20px);
  transform: translateY(-20px);
  overflow: hidden;

  @include transition-delay(130ms);

  ul {
    list-style-type: none;
    padding: 0;
    background: $bg-megamenu;

    &:after {
      content: "";
      display: table;
      clear: both;
    }
  }

  li {
    display: inline-block;
    border-bottom: 1px solid $base-white;
    border-right: 1px solid $base-white;
    width: 33.33333333%;
    float: left;
    min-height: 130px;

    a {
      padding: 30px 50px;
    }

    /*&.first-row a {
      padding: 5px 60px 40px 60px;
    }

    &.last-row {
      border-bottom: 0;

      a {
        padding: 40px 60px 0 60px;
      }
    }*/

    &.last-in-row {
      border-right: 1px solid transparent;
    }
  }
}

.main-nav > ul > li:hover .megamenu-wrapper {
  opacity: 1;
  pointer-events: all;
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

.IE10 {

  .megamenu-wrapper {
    max-height: 0;
  }

  .main-nav > ul > li:hover .megamenu-wrapper {
    max-height: 450px;
  }
}

.megamenu-link {
  display: inline-block;
  height: 100%;
  width: 100%;
  background: $bg-megamenu;
  @include transition(200ms);

  .link-name {
    color: $light-blue;
    font-size: 20px;
    display: block;
    background-size: 14px 14px !important;
    background-repeat: no-repeat !important;
    background-position: 100% 50% !important;
    padding-right: 20px;
    margin-bottom: 10px;
    position: relative;
    @include transition(200ms);
    padding-right: 60px;

    &:before {
      width: 14px;
      height: 14px;
      font-size: 14px;
      position: absolute;
      right: 0;
      top: 9px;
      display: inline-block;
    }
  }

  .link-desc {
    color: $base-color;
    font-size: 16px;
    line-height: 24px;
    @include transition(200ms);
  }

  &:hover, &:focus {
    text-decoration: none;
    background: $light-blue;

    .link-name, .link-desc {
      color: $base-white;
    }
  }
}

.resp-nav .link-name:before {
  display: none;
}

.footer {
  background: $dark-blue;
  padding-top: 30px;

  &-top {
    padding: 30px 0;

    &:after {
      content: "";
      display: table;
      clear: both;
    }
  }

  &-bottom {
    border-top: 1px solid $base-color;
    padding: 25px 0 0 0;

    a {
      display: inline-block;
      margin: 0 20px 25px 0;
      opacity: 1;
      @include transition(200ms);

      &:hover, &:focus {
        outline: 0;
        opacity: 0.7;
      }

      img {
        height: 50px;
      }

      &:last-of-type {
        float: right;
        margin-right: 0;
      }
    }

    &:after {
      content: "";
      display: table;
      clear: both;
    }
  }

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  li {
    margin-bottom: 5px;
  }

  a {
    color: $base-white;
    font-size: 13px;

    &:hover, &:focus {
      color: $base-white;
    }
  }

  li:first-of-type a {
    color: $light-blue;
    font-size: 17px;

    &:hover, &:focus {
      color: $light-blue;
    }
  }

  &-col {
    display: inline-block;
    width: 100%;
    float: left;
    margin-bottom: 30px;

    /*li {
      display: none;
    }

    li:first-of-type {
      display: block;
    }*/

    @include media(sm) {
      width: 50%;
      min-height: 160px;
    };

    @include media(md) {
      width: 33.333333%;
    };

    @include media(lg) {
      width: 25%
    };

    @include media(xl) {
      width: 16.66666%;
    };
  }
}

.copyright {
  background: #00222b;
  color: $base-white;
  padding: 25px 0;
  font-size: 13px;

  a {
    color: $light-blue;
  }
}

@media screen and (max-width: $breakpoint-resp-menu) {
  .header-top-right {
    padding-right: 60px;
  }
}

.mobile-menu {
  opacity: 0;
  pointer-events: none;

  transform-style: preserve-3d;
  @include transition(200ms);
  -webkit-transform: translateY(-20px);
  -moz-transform: translateY(-20px);
  -ms-transform: translateY(-20px);
  transform: translateY(-20px);
}


header.mobile .mobile-menu {
  overflow-y: scroll;
  max-height: 100vh;
  padding-bottom: 200px;

  background: $bg-megamenu;
  position: fixed;
  top: 70px;
  left: 0;
  width: 100%;
  z-index: 1001;

  .resp-nav {
    height: 800px;
    margin-bottom: 500px;
  }

  ul {
    list-style-type: none;
  }

  .sub-list {

    .link-name {
      background-image: none !important;
    }
  }
}

header.mobile .mobile-menu.menu-open {
  opacity: 1;
  pointer-events: all;
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

.mobile-nav-btn {
    background: #002a36;
    border-radius: 0px;
    width: 40px;
    height: 40px;
    overflow: hidden;
    float: right;
    display: none;
    margin-right: 30px;
    position: relative;
    line-height: 0;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    position: absolute;
    top: 15px;
    right: 0px;

    span {
      width: 24px;
      background-color: #FFFFFF;
      height: 4px;
      border-radius: 0px;
      display: inline-block;
      position: absolute;
      left: 8px;

      -moz-transition: all ease-in-out 100ms;
      -o-transition: all ease-in-out 100ms;
      -webkit-transition: all ease-in-out 100ms;
      transition: all ease-in-out 100ms;
    }

    span.top-line {
      top: 10px;
    }

    span.middle-line {
      top: 19px;
    }

    span.bottom-line {
      top: 28px;
    }

    @media screen and (max-width: $breakpoint-resp-menu) {
      display: block;
    };
}

.mobile-nav-btn:hover {

  span.top-line {
    transform: translateY(-3px);
  }

  span.bottom-line {
    transform: translateY(3px);
  }
}

.mobile-nav-btn.menu-close span.middle-line{
  display:none;
}

.mobile-nav-btn.menu-close span.top-line{
    -ms-transform: rotate(45deg); /* IE 9 */
    -webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
    transform: rotate(45deg);
    @include transition(150ms);

    top: 18px;
}

.mobile-nav-btn.menu-close span.bottom-line{
    -ms-transform: rotate(-45deg); /* IE 9 */
    -webkit-transform: rotate(-45deg); /* Chrome, Safari, Opera */
    transform: rotate(-45deg);
    @include transition(150ms);
    top: 18px;
}

.mobile-nav-btn.menu-close:hover span.top-line,
.mobile-nav-btn.menu-close:hover span.bottom-line{
    margin-top:0px;
}

.resp-nav-list {
  margin: 0;
  padding: 20px;
  text-align: center;

  >li>a {
    display: inline-block;
    padding: 15px 0;
    font-size: 20px;
    color: $dark-blue;
    font-weight: 700;
  }

  >li {
    border-bottom: 1px solid $base-white;
  }

  >li ul {
    padding: 0;
    overflow: hidden;

    li {
      padding: 3px 0;

      &:last-of-type {
        margin-bottom: 15px;
      }
    }
  }

  /*>li.open ul {
    max-height: 300px
  }*/
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .header-top-right .socials a {
    font-size: 0;
  }

  .header-top-right .socials .ico-facebook:before {
    top: 11px;
  }

  .header-top-right .socials .ico-linkedin:before {
    top: 11px;
  }

  .header-top-right .socials .ico-instagram:before {
    top: 8px;
  }

  .header-top-right .socials .ico-youtube:before {
    top: 8px;
  }
}

@supports (-ms-ime-align:auto) {
  .header-top-right .socials a {
    font-size: 0;
  }

  .header-top-right .socials .ico-facebook:before {
    top: 11px;
  }

  .header-top-right .socials .ico-linkedin:before {
    top: 11px;
  }

  .header-top-right .socials .ico-instagram:before {
    top: 8px;
  }

  .header-top-right .socials .ico-youtube:before {
    top: 8px;
  }
}

/*@media all and (min-width: 1510px) {
  .header-bottom .main-nav > ul > li > a {
    padding-left: 70px;
    text-align: left;
  }

  .header-bottom .main-nav > ul > li > a:after {
    content: "";
    display: inline-block;
    width: 40px;
    height: 40px;
    background-size: cover !important;
    position: absolute;
    left: 10px;
    top: 10px;
  }

  .header-bottom .main-nav > ul > li:nth-of-type(1) > a:after {
    background: url('../img/MENU_o-skupine.png');
    background: url('../img/MENU_o-skupine.svg');
  }

  .header-bottom .main-nav > ul > li:nth-of-type(2) > a:after {
    background: url('../img/MENU_zemedelska-technika.png');
    background: url('../img/MENU_zemedelska-technika.svg');
  }

  .header-bottom .main-nav > ul > li:nth-of-type(3) > a:after {
    background: url('../img/MENU_stavebni-stroje.png');
    background: url('../img/MENU_stavebni-stroje.svg');
  }

  .header-bottom .main-nav > ul > li:nth-of-type(4) > a:after {
    background: url('../img/MENU_nakladni-automobily.png');
    background: url('../img/MENU_nakladni-automobily.svg');
  }

  .header-bottom .main-nav > ul > li:nth-of-type(5) > a:after {
    background: url('../img/MENU_osobni-automobily.png');
    background: url('../img/MENU_osobni-automobily.svg');
  }

  .header-bottom .main-nav > ul > li:nth-of-type(6) > a:after {
    background: url('../img/MENU_kariera.png');
    background: url('../img/MENU_kariera.svg');
  }

}*/

.currency-selector-wrap {
  margin-left: 30px;
  width: 40px;
  height: 40px;
  position: relative;

  @media all and (max-width: 768px) {
    margin-right: 20px;
  }

  .currency-selector {
    position: absolute;
    left: 0;
    top: 0;
    width: 40px;
    height: 40px;
    border-radius: 50px;
    border: 2px solid $btn-blue;
    overflow: hidden;
    display: flex;
    flex-flow: column;
    text-align: center;
    background: $base-white;
    transition: all ease-out .3s;

    .active {
      padding-top: 8px;
      font-weight: 600;
      font-size: 13px;
      color: $btn-blue;
      cursor: default;
      transition: all ease-out .3s;
    }

    .options {
      margin-top: 11px;
    }

    a {
      font-size: 13px;
      display: inline-block;
      color: $base-white;
      padding: 3px;

      &:hover, &:focus {
        text-decoration: none;
        color: $dark-blue;
      }
    }

    &:hover {
      height: 100px;
      background: $btn-blue;

      .active {
        color: $base-white;
      }
    }
  }
}
