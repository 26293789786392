@import '_mixins/pux/breakpoints';
@import '_mixins/pux/css-rules';
@import '_variables';
@import "helpers";

.slick-wrapper {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.slick {
  position: relative;
  border-bottom: 1px solid $border-grey;
  border-top: 1px solid $border-grey;
  &:not(.slick-initialized) {
    display: none;
  }

  &-following {
    border-top: 0;
  }

  &-news {
    border-bottom: 0;
    border-top: 0;

    .slick-arrow {
      border: 0 !important;
    }
  }

  &.block-arrows {
    .slick-arrow {
      width: 40px;
      height: 100%;
      position: absolute;
      background: $lighter-blue;
      background-image: url("../img/icon-arrow-14x14.png");
      background-position: 50% 50% !important;
      background-size: 14px 14px !important;
      background-repeat: no-repeat !important;
      top: 0;
      border: 0;
      overflow: hidden;
      text-indent: 50px;
      outline: 0 !important;
      border-top: 1px solid $border-grey;
      border-bottom: 1px solid $border-grey;
      z-index: 2;
    }

    .slick-prev {
      left: 0;
      transform: rotateY(180deg);
    }

    .slick-next {
      right: 0;
    }

    &-dark {
      .slick-arrow {
        background-color: $base-color;
      }
    }

    &-no-border {
      .slick-arrow{
        border-top: 0;
        border-bottom: 0;
      }
    }
  }
}

.slick-slide {
  display: inline-block;
  vertical-align: top;
  z-index: 1;
  outline: 0 !important;
  background: $base-white;
  position: relative;
  @include transition(200ms);

  &.logo {
    height: 200px;
    width: 280px;
    border-right: 1px solid $border-grey;

    a {
      display: inline-block;
      height: 100%;
      width: 100%;
      padding: 0 20px;
      text-align: center;
    }

    img {
      position: relative;
      top: 50%;
      -webkit-transform: translateY(-50%) translateZ(0);
      -moz-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%) translateZ(0);
      filter: grayscale(1);
      -webkit-filter: grayscale(1);
      @include transition(200ms);
    }

    &.first {
      text-align: center;
      font-size: 20px;

      >div {
        @include vertical-pos(50%);
      }
      strong {
        display: block;
      }
    }

    &:not(.first):hover, &:not(.first):focus {
      background: $bg-slide;

      img {
        filter: grayscale(0);
        -webkit-filter: grayscale(0);
      }
    }
  }

  &.icon {
    height: 250px;
    padding: 50px 0;
    text-align: center;
    border-right: 1px solid $border-grey;

    .icon-img {
      width: 100px;
      height: 100px;
      margin: 0 auto 25px auto;

      img {
        width: 100%;
        float: left;
      }


    }
  }

 /*&.product {
    height: 520px;
    border-right: 1px solid $border-grey;
    color: $base-color;

    .product-img {
      background-size: cover !important;
      background-position: 50% 50% !important;
      height: 270px;
      @include transition(200ms);
    }

    .product-text {
      padding: 30px;
      font-size: 16px;
      background-size: 14px 14px !important;
      background-position: 90% 90% !important;
      background-repeat: no-repeat !important;

      @include media(md) {
        padding: 50px;
      };

      .product-name {
        font-weight: 700;
        color: $light-blue;
        position: relative;
        display: block;
        margin-bottom: 15px;
        font-size: 20px;

        &:after {
          content: "";
          position: absolute;
          width: 0;
          height: 2px;
          background: $light-blue;
          bottom: 0;
          left: 0;
          @include transition(200ms);
        }
      }
    }

    &:hover, &:focus {
      outline: 0;
      text-decoration: none;
      color: $base-color;

      .product-img {

      }

      .product-name:after {
        width: 100%;

      }
    }
  }*/

  .item-hover {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: -20px;
    background: rgba(72,175,196,0.85);
    opacity: 0;
    @include transition(200ms);

    .text {
      color: #FFFFFF;
      padding: 20px 60px;
      font-size: 14px;
      top: -20px;
      @include transition(300ms);
      @include vertical-pos(25%);
      line-height: 22px;
    }
  }

  &:hover, &:focus {
    .item-hover {
      top: 0;
      opacity: 1;

      .text {
        @include vertical-pos(50%);
      }
    }
  }
}

.slick.block-arrows .slick-arrow {
  @include transition(200ms);
}

.slick.block-arrows .slick-next.slick-disabled {
  right: -40px;
}

.slick.block-arrows .slick-prev.slick-disabled {
  left: -40px;
}

@media all and (max-width: 500px) {
  .slick.block-arrows .slick-arrow {
    display: none !important;
  }
}

.slick-dots {
  position: absolute;
  top: 20px;
  left: 50px;
  list-style-type: none;
  padding: 0;
  margin: 0;
  height: 20px;

  @include media(md) {
    top: initial;
    bottom: 15px;
  };

  li {
    display: inline-block;
    margin-right: 15px;
    margin-bottom: 5px;

    @include media(lg) {
      margin-right: 25px;
    };

    button {
      width: 12px !important;
      height: 12px !important;
      min-width: 12px !important;
      min-height: 12px !important;
      max-width: 12px !important;
      max-height: 12px !important;
      font-size: 0;
      background: #cecccd;
      border-radius: 50%;
      @include transition();
      text-indent: 0;
      overflow: hidden;
      border: 0;
      outline: none !important;

      // @include media(lg) {
      //   width: 12px;
      //   height: 12px;
      // };
    }


    &:last-of-type {
      margin-right: 0;
    }

    &.slick-active {

      button {
        background: $light-blue;
      }
    }
  }
}
