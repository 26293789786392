@import '_mixins/pux/breakpoints';
@import '_mixins/pux/css-rules';
@import '_variables';
@import "helpers";

.company-info {

  &-col {
    width: 100%;
    display: inline-block;
    float: left;

    @include media(sm) {
      width: 50%;
    };

    @include media(lg) {
      width: 33.333%;
    };

    @include media(xxl) {
      width: 20%;
    };
  }

  &-4col {
    .company-info-col {
      width: 100%;
      display: inline-block;
      float: left;

      @include media(sm) {
        width: 50%;
      };

      @include media(xl) {
        width: 25%;
      };
    }
  }

  &-bottom {
    margin-top: 60px;

    &:after {
      content: "";
      display: table;
      clear: both;
    }
  }

  .info-item {
    padding: 0 30px;
    text-align: center;
    font-weight: 300;
    margin-bottom: 30px;

    @include media(md) {
      padding: 0 60px;
    };

    &-number {
      padding-bottom: 15px;
      margin-bottom: 15px;
      border-bottom: 1px solid $border-grey;
      font-size: 50px;
      font-weight: 700;
      color: #cacdcd;
    }
  }
}

#static-map {
  height: 300px;
  position: relative;

  display: inline-block;
  width: 100%;
  float: left;
  background: url("../img/mapa-beta.jpg");
  background-size: cover !important;
  background-position: 50% 0 !important;
  color: $base-white;


  @include media(md) {
    height: 600px;
  };

  .info-stripe {
    padding: 15px;
    text-align: center;
    font-weight: 300;
    background: rgba(0,42,54,0.75);
    position: absolute;
    bottom: 0;
    @include transition(200ms);
    width: 100%;
    transform: translateZ(0);

    strong {
      color: $light-blue;
    };
  }



  .map-overlay {
    background: rgba(0,42,54, 0.6);
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    @include transition(250ms);

    &:before {
      content: "";
      display: inline-block;
      width: 150px;
      height: 160px;
      background: url("../img/Search_Lupa.svg");
      position: absolute;
      left: 50%;
      margin-left: -75px;
      top: 50%;
      margin-top: -80px;
      background-repeat: no-repeat;
      background-size: cover !important;
    }
  }

  &:hover {
    text-decoration: none;
    color: $base-white;

    .map-overlay {
      opacity: 1;
    }
  }
}
