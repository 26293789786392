@import '_mixins/pux/breakpoints';
@import '_mixins/pux/css-rules';
@import '_variables';
@import "helpers";

.filter-cars {
  padding: 20px 10px 30px;
  @include flex-block();
  @include flex-wrap(wrap);

  @include media(md) {
    padding: 20px 40px 30px;
  };

  .filter-item-wrapper {
    //@include flex-basis(100%);
    width: 100%;
    padding: 0 10px;

    @include media(md) {
      /*@include flex-basis(49%);
      @include flex-grow(1);
      max-width: 50%;*/
      width: 50%;
      padding: 0 10px;
    };

    @include media(lg) {
      /*@include flex-basis(32%);
      @include flex-grow(1);
      max-width: 33.3333333%;*/
      width: 33.333%;
      padding: 0 10px;
    };

    @include media(xxl) {
      /*@include flex-basis(24%);
      @include flex-grow(1);
      max-width: 25%;*/
      width: 25%;
      padding: 0 10px;
    };

    &-submit {
      input {
        width: 100%;
        text-align: center;
      }
    }
  }

  .filter-name {
    font-size: 16px;
    margin-bottom: 15px;
  }

  .filter-item {

    .button {
      //background-color: $bg-slide;
      background-color: $base-white;
      border-color: transparent;
    }
  }

  .selectric {
    background: $base-white;
  }

  .selectric-items {
    background: $base-white;
  }

}

#slider-ui {
  width: calc(100% - 10px);
  margin: 5px auto 60px;

  @include media(md) {
    width: calc(100% - 220px);
    margin: 5px auto 0;
    position: relative;
    top: 15px;
  };
}

.price-filter, .kilometer-filter, .year-filter, .horse-power-filter, .engine-hours-filter, .min-output-filter {
  width: 100%;
  padding:10px;
  background: #fff;

  @include media(md) {

  };

  input#min, input#max {
    position: absolute;
    width: 110px;
    top: 20px;
    border-color: transparent;
    font-weight: bold;
    font-size: 16px;
    font-family: $base-font;
    color: $base-color;
    font-weight: 400;

    @include media(md) {
      top: 9px;
    };
  }

  input[disabled] {
    background: $base-white;
    opacity: 1;
  }

  input#min {
    left: 0;
  }

  input#max {
    right: 0;
    text-align: right;
  }

  .noUi-target {
    border-radius: 0;
    border: 0;
    background: $bg-slide;
  }

  .noUi-horizontal {
    height: 6px;
  }

  .noUi-connect {
    background: $light-blue;
    box-shadow: none;
    height: 2px;
    top: 2px;
  }

  .noUi-handle {
    border-radius: 0;
    border: 0;
    box-shadow: none;
    background:  $light-blue;
    height: 11px;
    width: 11px;
    left: -6px;
    top: -3px;
    cursor: pointer;

    &:after, &:before {
      display: none;
    }
  }

  .noUi-base {
    background: $bg-slide;
    cursor: pointer;
  }
}

.cars {

  .car {
    color: $base-color;
    border: 1px solid $border-grey;
    border-collapse: collapse;
    text-decoration: none !important;
    position: relative;
    overflow: hidden;
    background: $base-white;
    display: flex;
    flex-flow: column;

    .car-hover {
      opacity: 0;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: rgba(72,175,196,0.65);
      @include transition(350ms);
      @include translate3d(0,-20px,0);
      opacity: 0;
      pointer-events: none;
      color: $base-white;
      text-align: center;
      padding-top: 80px;
      z-index: 1;

      span {
        border: 2px solid $base-white;
        font-weight: bold;
        font-size: 17px;
        padding: 15px 55px;
        opacity: 0;
        position: relative;
        @include transition(250ms);
        @include transition-delay(150ms);
      }
    }

    &:hover {
      .car-hover {
          @include translate3d(0,0,0);
          opacity: 1;
          padding-top: 100px;

          span {
            opacity: 1;
          }
      }

      .car-name {
        color: $base-white;
      }

      .car-info {
        color: $base-white;
      }

      .price-block {
        background: $base-white;
        color: #00afc2;
      }
    }

    &-top {
      &:before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 1;
        border: 2px solid $light-blue;
        pointer-events: none;
        background: transparent;
      }
    }
  }

  .car-info {
    padding: 15px 15px 90px 15px;
    font-size: 16px;
    position: relative;
    z-index: 5;
    flex-grow: 1;
    transition: all ease-in-out 200ms;

    table td:last-of-type {
      min-width: 95px;
    }

    thead {
      display: none;
    }
  }

  .car-name {
    font-size: 18px;
    font-weight: bold;
    color: $light-blue;
    margin-bottom: 5px;
    //min-height: 60px;
    @include transition();
  }

  .car-image {
    height: 200px;
    background-size: contain !important;
    background-repeat: no-repeat !important;
    background-position: 50% 50% !important;
    position: relative;
    overflow: hidden;
  }

  table {
    width: 100%;

    td {
      padding: 0px 0;
    }

    td:last-of-type {
      text-align: right;
    }

    &:last-of-type {

      td:last-of-type {
        text-align: right;
        font-weight: bold;
      }

      tr:last-of-type {
        /*td {
          padding-top: 20px;

          &:last-of-type {
            font-size: 20px;
          }
        }*/
      }
    }
  }
}

.corner {
  position: absolute;
  display: inline-block;
  width: 145px;
  height: 145px;
  font-size: 26px;
  font-weight: 700;
  color: $base-white;
  letter-spacing: 1px;
  text-align: center;
  padding-top: 100px;
  z-index: 10;

  &-discount {
    background: #004854;
    right: -73px;
    top: -73px;
    @include rotate;
  }

  &-top {
    background: #00afc2;
    top: -73px;
    left: -73px;
    @include rotate(-45deg);
  }
}



.car-detail thead {
    display: none;
}

.car-detail-image, .car-detail-intro {
  @include media(xl) {
    height: 570px;
  };
}

.car-detail-image {
  text-align: center;
  margin-bottom: 50px;
  overflow: hidden;

  @include media(xl) {
    width: 50%;
    position: absolute;
    right: 0;
    top: 0;
    @include flex-block;
    @include align-items(center);
    margin-bottom: 0;
  };

  img {
    max-width: 100%;
  }
}

.car-detail-intro {
  position: relative;
  overflow: hidden;

  h1 {
    font-size: 20px;


    @include media(md) {
      font-size: 34px;
      margin-bottom: 40px;
      margin-top: 0;
      padding-top: 40px;
    };
  }

  .perex {
    font-weight: 300;
    margin-bottom: 40px
  }

  @include media(xl) {
    border-right: 1px solid $border-grey;
    width: 50%;
    padding-right: 40px;

    .text {
      max-width: 550px;
    }


  };

  .price-block {
    background: #f2f2f2;
    color: $base-color;
    position: relative;
    overflow: hidden;
    text-align: right;
    padding-right: 15px;
    margin-bottom: 1px;
    transition: all ease-in-out 200ms;

    @include media(sm) {
      text-align: center;
      padding-right: 0;
    };

    height: 130px;

    .main-price {
      font-size: 32px;
      margin: -20px 0;

      @include media(sm) {
        font-size: 40px;
        margin: -30px 0;
      }
    }

    .novat-price {
      font-size: 13px;

      @include media(sm) {
        font-size: 16px;
      }
    }

    .list-price {
      font-size: 13px;

      @include media(sm) {
        font-size: 16px;
      }
    }

    .corner {
      right: initial;
      width: 145px;
      height: 145px;
      left: -73px;
      @include rotate(-45deg);
    }
  }
}

.car-detail {
  padding: 25px 0 25px;
  font-size: 16px;

  .row {
    margin-left: 0;
    margin-right: 0;

    >* {
      @include media(md) {
        padding-left: 30px;
        padding-right: 30px;
      }
    }
  }

  h2 {
    margin-bottom: 30px;
  }

  table {
    width: 100%;

    tr {
      border-bottom: 1px solid $bg-slide;
    }

    td {
      padding: 15px 0;
    }

    td:first-of-type {

      @include media(md) {
        padding-left: 30px;
      };
    }

    td:last-of-type {
      text-align: right;
      font-weight: bold;

      @include media(md) {
        padding-right: 30px;
      };
    }
  }

  .info-item {
    border-bottom: 1px solid $bg-slide;
    padding: 15px 0;

    &:last-of-type {
      border-bottom: 0;
    }

    &-heading {
      margin-bottom: 20px;
    }

    &-text {
      font-weight: bold;
    }
  }

  .col-lg-12 {
    margin-top: 40px;

    @include media(xl) {
      margin-top: 0;
    };

  }
}

.auto-filter-wrapper {
  padding-top: 60px;
  @include flex-block;
  @include flex-wrap(wrap);

  .filter-item {
    padding-left: 1px;
    padding-right: 1px;
    width: 100%;
    margin-bottom: 15px;
    @include flex-basis(100%);

    @include media(sm) {
      width: 50%;
      @include flex-basis(50%);
    };

    @include media(lg) {
      width: 33.333%;
      @include flex-basis(33.333%);
    };

    @include media(xxl) {
      width: 25%;
      @include flex-basis(25%);
    };

    .button {
      padding: 15px 20px 15px 30px;
      border-bottom: 0;
    }
  }

  &:after {
    content: "";
    display: table;
    clear: both;
  }

  .select-box > span {
    font-size: 14px;
  }

}

.pux-infinite-repeater-result-count-wrapper {
  font-size: 14px;
}

.product-list-cars {

    .car-image {

      background-size: cover !important;
    }

    .novat {
      font-size: 14px;
      font-weight: 400;
    }

    .cars {
      @include flex-block;
      @include flex-wrap(wrap);
    }


}

.car-detail-intro {

  .perex {
    margin-bottom: 0;

    table {
      font-size: 16px;
      width: 100%;
      margin-bottom: 15px;

      @include media(md) {
        margin-bottom: 40px;
      };

      td:last-of-type {
        text-align: right;
        font-weight: 700;
      }
    }
  }

  .detail-prices {
    width: 100%;
    max-width: 400px;
    margin: 10px 0 30px;
    //text-align: right;
    padding-right: 15px;

    .price {
      display: block;
      font-size: 20px;
      padding: 10px 0;
      border-bottom: 1px solid $border-grey;
      line-height: 34px;

      &.car-detail-price-vat {
        font-size: 22px;
      }

      &:last-of-type {
        border-bottom: none;
      }
    }
  }

  .btn {
    width: 100%;
    text-align: center;
  }
}

.product-detail-wrapper {
  position: relative;

  .car-detail-image {
    background-size: cover!important;
    background-position: 50% 50% !important;
    width: 100%;
    padding-bottom: 60%;
    display: inline-block;
    position: relative;
    margin-bottom: 0;
    margin-top: 40px;

    @include media(xl) {
      width: 50%;
      padding-bottom: 0;
      position: absolute;
      margin-top: 0;
    };
  }

  .gallery-thumbs {
    top: inherit;
    left: 0;
    bottom: 0;
    padding-top: 0;
    padding-bottom: 58%;
    height: 75px;
    width: 640px;

    @include media(xl) {
      right: 0;
      left: inherit;
      height: auto;
      padding-top: 470px;
      top: 0;
      bottom: inherit;
      padding-bottom: 0;
      width: 50%;
    };

    .thumb {
      display: none;

      &:nth-of-type(1),
      &:nth-of-type(2),
      &:nth-of-type(3) {
        display: inline-block;
      }
    }
  }

  .arrow-scroll {
    z-index: 2;
  }
}

.novat-container {
  font-size: 14px !important;
  font-weight: 400 !important;
  padding-top: 5px !important;
}

.vat-price {
  font-size: 20px;
  font-weight: 700;
}

.pager {
  width: 100%;
  text-align: center;
  padding: 30px 15px;

  .aspNetDisabled {
    display: none;
  }

  a {
    @include transition;
  }

  a:hover, &:focus {
    text-decoration: none;
    color: #004854;
  }

  &-previous, &-first, &-next, &-last {
    display: none;
  }
}

.product-detail-wrapper {

  .contacts.partner-detail {

    h3 {
      display: none;
    }

    .row.margin-bottom-70 {
      margin-bottom: 40px;



      .col-lg-2 {
        display: none;
      }
    }
  }

  .detail-prices {
    text-align: right;

    .car-detail-price-note {
      text-align: left;
    }
  }
}

.related-buttons-wrapper {
  text-align: center;
  padding-top: 30px;
}

.product-list-cars {
  .price-filter,
  .kilometer-filter,
  .year-filter,
  .horse-power-filter,
  .engine-hours-filter,
  .min-output-filter {
    @include flex-block;
    @include align-items(center);


    input {
      display: block;
      border: none;
      background: transparent;
      pointer-events: none;
      max-width: 92px;
      font-size: 13px;

      &:first-of-type {
        @include order(1);
      }

      &:last-of-type {
        @include order(3);
        text-align: right;
      }
    }

    .noUi-target {
      @include order(2);
      @include flex-grow(1);
    }
  }

  .kilometer-filter {
    input {
      max-width: 85px;
    }
  }

  .horse-power-filter, .min-output-filter {
    input {
      max-width: 60px;
    }
  }

  .year-filter {
    input {
      max-width: 50px;
    }
  }

  .engine-hours-filter {
    input {
      max-width: 70px;
    }
  }

  // div[id*="WebProductListFilter"] {
  //   width: 100%;
  // }

  .filter-item-wrapper-submit {
    padding-top: 10px;
  }
}

.related-products {
  .filter-item-wrapper.filter-item-wrapper-submit.filter-cars {
    display: none;
  }

  .pager {
    display: none;
  }
}

.price-block-absoluter{
  position: absolute;
  bottom:10px;
  width:100%;
  padding:0 15px;
  left:0;
}

.price-block {
  background: #00afc2;
  color: $base-white;
  height: 75px;
  width: 100%;
  @include flex-block;
  @include justify-content(center);
  @include align-items(center);
  @include flex-wrap(wrap);
  text-align: center;
  margin-top: 10px;
  transition: all ease-in-out 200ms;

  &>div {
    width: 100%;
  }

  .list-price {
    font-size: 13px;
    margin-bottom: -8px;
    //height: 35px;
  }

  .main-price {
    font-size: 25px;
    font-weight: 700;
    letter-spacing: 1px;
    margin: -20px 0;
  }

  .novat-price {
    font-size: 13px;
    margin-top: -8px;
    //height: 35px;
  }
}

.car-sorter-wrap {
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 12px;

  @include media(md) {
    padding-left: 40px;
    padding-right: 40px;
  };

  @include media(xl) {
    border-bottom: 1px solid #d4d7d7;
  };

  .car-sorter {
    @include flex-block;
    @include flex-wrap(wrap);

    label {
      text-align: center;
      background: $base-white;
      cursor: pointer;
      @include transition;
      color: $light-blue;
      position: relative;
      top: 1px;
      margin: 0;
      padding: 15px 40px;
      border: 1px solid transparent;
      font-weight: 700;
      font-size: 16px;
      width: 100%;

      @include media(sm) {
        width: 50%;
        border-left: 1px solid $bg-slide;
        border-right: 1px solid $bg-slide;
        border-bottom: 1px solid $bg-slide;
      };

      @include media(md) {
        width: 33.333%;
      };

      @include media(xl) {
        width: 20%;
        border-bottom: 1px solid #d4d7d7;
      };

      &:hover, &:focus {
        background: $light-blue;
        color: $base-white;
      }
    }

    input[type='radio']:checked + label {
      background: $bg-slide;
      border: 1px solid #d4d7d7;
      color: $base-color;
      cursor: default;
      pointer-events: none;

      @include media(xl) {
        border-bottom: 1px solid $bg-slide;
      };
    }
  }
}

.detail-form-wrapper {
  padding: 20px 0;

  @include media(md) {
    padding: 40px 0;
  };

  .narrow-form-wrapper {
    max-width: 100%;
    padding-top: 30px;

    input {
      margin-bottom: 10px;
    }

    textarea {
      margin-bottom: 20px;
    }

    .recaptcha {
      margin-bottom: 30px;

      @include media(md) {
        margin-bottom: 0;
      };
    }

    .submit {
      text-align: right;
    }
  }
}

.price-block-wrap {
  display: flex;
  flex-wrap: wrap;

  .price-block, .btn {
    width: 50%;
    max-width: 100%;
  }

  .price-block {
     background: #004854;
     color: $base-white;
     text-align: left;
     border-right: 1px solid $base-white;
     margin-top: 0;
     padding: 0 30px;
  }

  .btn {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @include media(lg) {

  };
}

.agrobazar-detail {

  .car-detail-intro {

    table {
      font-size: 18px;

      tr {
        border-bottom: 1px solid #d4d6d5;

        td {
          padding-top: 5px;
          padding-bottom: 5px;

          &:first-of-type {
            font-weight: 400;
          }
        }
      }
    }

    h1 {

      @include media(lg) {
        font-size: 40px;
      };

      @include media(xl) {
        padding-top: 60px;
      };
    }
  }

  @include media(xl) {
    .car-detail-image, .car-detail-intro {
      height: 710px;
    }
  };

  .gallery-thumbs {
    top: inherit;
    text-align: left;
    width: 100%;
    display: none;

    @include media(lg) {
      display: block;
      top: calc(100% - 100px);

    };

    @include media(xl) {
      width: 50%;
      bottom: 1px;
      display: block;
      top: inherit;
    };

    .thumb {
      box-shadow: none;
      margin: 0;
      height: 100px;
      width: 16.667%;
      display: none;

      &:nth-of-type(1) {
        display: inline-block;
      }
      &:nth-of-type(2) {
        display: inline-block;
      }
      &:nth-of-type(3) {
        display: inline-block;
      }
      &:nth-of-type(4) {
        display: inline-block;
      }
      &:nth-of-type(5) {
        display: inline-block;
      }
      &:nth-of-type(6) {
        display: inline-block;
        background: #00afc2 !important;
        background-image: none !important;
        opacity: 1 !important;
        position: relative;

        &:before {
          content: '\e902';
          font-family: 'icomoon' !important;
          speak: none;
          font-style: normal;
          font-weight: normal;
          font-variant: normal;
          text-transform: none;
          line-height: 1;
          -webkit-font-smoothing: antialiased;
          font-size: 22px;
          color: $base-white;
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          right: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          background: transparent;
        }
      }
    }
  }

  .perex .text {
    margin-bottom: 30px;
    font-weight: 400;
  }

  .main-price {
    font-size: 28px !important;
  }
}



.discount-total .car-detail-intro, .discount-total {

   .corner.corner-discount {
    width: 100%;
    top: inherit;
    bottom: 0;
    right: 0;
    text-align: center;
    transform: rotate(0);
    padding: 5px 0;
    font-size: 20px;
    height: auto;
    z-index: 0;
   }
 }

 .discount-total .car-detail-intro {

    .corner.corner-discount {
      position: relative;
      left: 0;
      padding: 10px 0;
    }
  }

.inverted-prices {

  .price-block {

    .list-price {
      order: 2;
    }

    .main-price {
      order: 1;
      margin: 0 0 -19px;
    }

    .novat-price {
      order: 3;
    }
  }

  .car-detail-intro {
    .price-block .main-price {
      margin: -11px 0 -17px;
    }

    .price-block .novat-price {
      margin-top: -8px;
    }

    .price-block .list-price {
      margin-bottom: 0;
    }
  }
}

.product-detail-wrapper {

  .discount-total.agrobazar-detail {

    .car-detail-intro {

      .corner.corner-discount {
        right: -72px;
        top: -72px;
        left: initial;
        width: 145px;
        height: 145px;
        transform: rotate(45deg);
        position: absolute;
        display: inline-block;
        font-size: 14px;
        font-weight: 700;
        color: #FFFFFF;
        text-align: center;
        padding-top: 117px;
        z-index: 10;
      }
    }
  }
}
