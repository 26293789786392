@import '_mixins/pux/breakpoints';
@import '_mixins/pux/css-rules';
@import '_variables';
@import "helpers";

.search-page {

  span[style] {
    background: transparent !important;
    font-weight: 700;
    color: $light-blue;
  }
}

.agrotec-pager {



  a:hover, a:focus {
    text-decoration: none;
    color: $base-color;
  }
}

.cookie-law-approval-wrapper {
  padding: 10px 0 !important;
  font-size: 14px;

  a {
    color: $light-blue;
  }

  .cookie-law-approval-close {
    background: $light-blue;
    border: 1px solid $light-blue;
    border-radius: 0;
    color: $base-white;

    &:hover, &:focus {
      outline: 0;
      background: $dark-blue;
      border: 1px solid $dark-blue;
      color: $base-white;
    }
  }
}

.download {
  border: 1px solid $bg-slide;
  border-top: 0;
  text-align: center;
  padding: 50px 30px 20px;
  height: 270px;
  background: $base-white;
  position: relative;

  .img img {
    max-height: 100px;
  }

  ul {
    text-align: left;
    list-style-type: none;
    padding: 0;
    margin: 0;
    position: absolute;
    bottom: 0;
    padding-bottom: 30px;

    a {
      color: #bebebe;
      font-weight: 300;
      span {
        position: relative;
        padding-left: 30px;
        color: $light-blue;
        font-weight: 400;
        margin-right: 5px;
        @include transition(150ms);

        &:before {
          width: 14px;
          height: 14px;
          font-size: 14px;
          position: absolute;
          left: 0;
          top: 9px;
          display: inline-block;
          -webkit-transform: rotate(90deg);
          -moz-transform: rotate(90deg);
          -ms-transform: rotate(90deg);
          transform: rotate(90deg);
        }
      }

      &:hover, &:focus {
        text-decoration: none;
        color: #bebebe !important;
        span {
          color: #bebebe !important;
          outline: 0;
        }
      }
    }
  }
}

.downloads .product {
  height: 345px !important;

  .name {
    color: $light-blue;
    padding-left: 25px;
    position: relative;
    @include transition(150ms);
    font-size: 20px;

    &:before {
      -webkit-transform: rotate(90deg) translateZ(0);
      -moz-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
      transform: rotate(90deg);

      display: inline-block;
      position: absolute;
      left: 0;
      top: 9px;
      font-size: 14px;


    }
  }

  &:hover, &:focus {
    .name {
      color: $base-color;
    }
  }
}

.downloads .product .product-text {
  padding: 25px !important;
}

/* multiselect */
.multiselect-checkbox li~li::before {
  content: "\2610" !important;
  width: 1.3em !important;
  text-align: center !important;
  display: inline-block !important; }

.multiselect-checkbox li~li.selected::before {
  content: "\2611" !important; }

  .cookies-inner-accept.cookies-btn.btn-primary,
.cookies-modal-footer-left .cookies-btn.btn-primary {
  background-color: $light-blue;
  &:hover,
  &:focus {
    background-color: darken($light-blue, 5);
  }
}
