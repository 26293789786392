@import '_mixins/pux/breakpoints';
@import '_mixins/pux/css-rules';
@import '_variables';
@import "helpers";

$item-height: 200px;
$item-width: 200px;
$item-fold-width: 230px;
$item-alternating-spacing: 125px;
$item-spacing: $item-fold-width + 40px;

.supaslider {
  position: relative;

  display: none;

  @media all and (min-width: 390px) {
    display: block;
  }

  &-viewport {
    overflow: hidden;
    height: $item-height * 2 + 45px;
    padding: 0 80px;
    position: relative;

    @media all and (min-width: 640px) {
      height: $item-height + 45px;
    }
  }

  &-content {
    position: relative;
    @include transition(250ms);
    left: 0;
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
  }

  &-nav {
    position: absolute;
    width: 100%;
    top: 50%;
    left: 0;
    color: $base-white;
    text-align: center;



    .supaslider-prev, .supaslider-next {
      position: absolute;
      width: 40px;
      height: 40px;
      background: $light-blue;
      top: -20px;
      cursor: pointer;
      opacity: 1;
      @include transition(200ms);

      &:before {
        font-size: 16px;
        line-height: 40px;
      }

      &.disabled {
        pointer-events: none;
        opacity: 0.4;
      }
    }

    .supaslider-prev {
      left: 0;

      transform: rotateY(180deg);
    }

    .supaslider-next {
      right: 0;
    }
  }

  &-item {
    display: inline-block;
    width: $item-width;
    height: $item-height;
    position: relative;
    float: left;
    margin-right: $item-spacing;
    transform-style: preserve-3d;
    opacity: 0;
    @include transition(300ms);

    &.shown {
      opacity: 1;
    }

    &:before {
      content: "";
      position: absolute;
      @include triangle-bottom(20px, 20px, 35px, $base-white);
      left: $item-width / 2 - 10px;
      bottom: -34px;

      @media all and (max-width: 575px) {
        @include triangle-bottom(20px, 20px, 35px, $base-color);
      }
    }

    &:after {
      content: "";
      position: absolute;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: $light-blue;
      left: $item-width / 2 + 5px;
      bottom: -45px;
    }

    .item-left {
      position: absolute;
      height: 100%;
      width: 100%;
      background: $base-white;
    }

    .item-right {
      position: absolute;
      height: $item-height;
      width: $item-fold-width;
      left: $item-width + 1px;
      top: 0;
      background: transparent;
      backface-visibility: hidden;
      transform-style: preserve-3d;
      -webkit-perspective:900;
      perspective:900;
      transform: translateX(-19px);

      @include transition(1ms);
      @media all and (min-width: 575px) {
        @include transition(500ms);
      };



      .backside, .frontside {
        @include transition(1ms);
        @media all and (min-width: 575px) {
          @include transition(500ms);
        };

        backface-visibility: hidden;
        position: absolute;
        height: 100%;
        width: $item-fold-width + $item-width;
        top: 0;
        left: 0 - $item-width;
        z-index: 222;
      }

      .bg, .text {
        height: $item-height;
        width: $item-fold-width;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 5;
      }

      .bg {
        right: inherit;
        left: 0;
        background: $base-white;
      }

      .text {
        background: $base-color;
        color: $base-white;
      }

      .backside {
        -webkit-transform: rotate3d(0, 0, 0, 180deg);
        -moz-transform: rotate3d(0, 0, 0, 180deg);
        -ms-transform: rotate3d(0, 0, 0, 180deg);
        transform: rotate3d(0, 0, 0);
      }

      .frontside {
        -webkit-transform: rotate3d(0, -1, 0, 180deg);
        -moz-transform: rotate3d(0, -1, 0, 180deg);
        -ms-transform: rotate3d(0, -1, 0, 180deg);
        transform: rotate3d(0, -180deg, 0);
      }

      &.unfolded {
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        transform: translateX(0);

        .backside {
          -webkit-transform: rotate3d(0, 1, 0, 180deg);
          -moz-transform: rotate3d(0, 1, 0, 180deg);
          -ms-transform: rotate3d(0, 1, 0, 180deg);
          transform: rotate3d(0, 180deg, 0);
        }

        .frontside {
          -webkit-transform: rotate3d(0, 0, 0, 180deg);
          -moz-transform: rotate3d(0, 0, 0, 180deg);
          -ms-transform: rotate3d(0, 0, 0, 180deg);
          transform: rotate3d(0, 0, 0);
        }

      }
    }
  }

  .text {
    padding: 20px 30px;
    font-size: 16px;

    &-heading {
      font-size: 20px;
      font-weight: 700;
      margin-bottom: 0;
    }

    &-info {
      color: $base-white !important;
    }
  }

  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: 4px;
    left: 0;
    background: $base-white;
  }

  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

.supaslider-alternating {

  .supaslider-viewport {

    height: $item-height * 2 + 45px;

    @media all and (min-width: 640px) {
      height: $item-height + 45px;
    }

    @include media(md) {
      height: $item-height * 2 + 80px;
    };
  }

  /*.supaslider-nav {
    top: inherit;
    bottom: 5px;
    left: 0;
    color: $base-white;
    text-align: center;
  }*/

  &:before {

    @include media(md) {
      bottom: inherit;
      top: calc(50% - 1px);
    };

  }

  .supaslider-item {
    margin-right: $item-alternating-spacing;

    @media all and (max-width: 639px) {

      text-align: center;

      .item-right {
        top: 200px;

        @media all and (max-width: 575px) {
          top: 201px;
        }
      }

      &:before {
        bottom: -235px;
      }

      &:after {
        bottom: -245px;
      }

      .item-left {
        //width: 100%;
        width: 230px;

        .text {
          //width: 100%;
        }
      }

      .item-right {
        //width: 100%;
      }

      .text {
        left: 0;
      }
    }
  }

  .supaslider-item:nth-of-type(even) {
    @include media(md) {
      top: $item-height + 80px;

      &:before {
        @include triangle-top(20px, 20px, 35px, $base-white);
        border-top: 0;
        bottom: inherit;
        top: -35px;
      }

      &:after {
        bottom: inherit;
        top: -45px;
      }
    };
  }
}
