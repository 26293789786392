@import '_mixins/pux/breakpoints';
@import '_mixins/pux/css-rules';
@import '_variables';
@import "helpers";

$resp-img-size: 320px;



@mixin gradient-horizontal($fromColor, $fromPercent, $toColor) {
  background: -webkit-linear-gradient(left, $fromColor $fromPercent, $toColor); /* For Safari 5.1 to 6.0 */
  background: -o-linear-gradient(right,  $fromColor $fromPercent,  $toColor); /* For Opera 11.1 to 12.0 */
  background: -moz-linear-gradient(right,  $fromColor $fromPercent,  $toColor); /* For Firefox 3.6 to 15 */
  background: linear-gradient(to right,  $fromColor $fromPercent, $toColor); /* Standard syntax */
}

@mixin gradient-vertical($fromColor, $fromPercent, $toColor) {
  background: -webkit-linear-gradient(bottom, $fromColor $fromPercent, $toColor); /* For Safari 5.1 to 6.0 */
  background: -o-linear-gradient(top, $fromColor $fromPercent, $toColor); /* For Opera 11.1 to 12.0 */
  background: -moz-linear-gradient(top, $fromColor $fromPercent,$toColor); /* For Firefox 3.6 to 15 */
  background: linear-gradient(to top, $fromColor $fromPercent, $toColor); /* Standard syntax */
}

.content-row {
  position: relative;
  padding: 50px 0;


  @include media(lg) {
    padding: 55px 0;
    min-height: 400px;
  };

  h2, .h2 {
    margin-bottom: 30px;
  }

  .perex {
    margin-bottom: 30px;
  }

  .row-text-small {
    font-size: 16px;
    margin-bottom: 30px;
  }

  .btn {
    margin-top: 10px;
  }

  ul {
    list-style-type: none;

    li:before {
      content: "";
      display: inline-block;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: $light-blue;
      position: relative;
      left: -15px;
      top: -2px;
    }
  }

  &-img, &-bg {
    position: absolute;
    width: 100%;
    top: 0;
    height: 100%;

    @include media(lg) {
      width: 50%;
    };
  }

  &-img {
    z-index: 1;
    background-size: cover !important;
    background-position: 50% 50% !important;
    height: $resp-img-size;

    @include media(lg) {
      height: 100%;
    };
  }

  &-bg {
    z-index: 2;
  }

  &-text {
    z-index: 3;
    position: relative;
    /*padding: 0 30px;*/
  }

  &.smooth {
    .content-row-img {
      width: 100%;

      @include media(lg) {
        width: 70%;
      };
    }

    .content-row-bg {
      width: 100%;
    }

    .content-row-bg {
      height: 400px;
      max-height: 100%;
      top: 0;
      @include gradient-vertical($base-white, 30%, rgba(255,255,255,0.0));

      @include media(lg) {
        height: 100%;
        @include gradient-horizontal($base-white, 30%, rgba(255,255,255,0.1));
      };

    }

    .content-row-text {
      margin-top: $resp-img-size / 2;

      @include media(lg) {
        margin-top: 0;
      };
    }
  }

  &.strict {

  }

  &.text-left {

    @include media(lg) {
      padding-right: 30px;
    };


    .content-row-text {
      float: left;
    }

    .content-row-img {
      right: 0;
      left: inherit;
    }

    .content-row-bg {
      left: 0;
      right: inherit;
    }

  }

  &.text-right {

    .content-row-text {
      float: right;

      @include media(lg) {
        padding: 0 30px;
      };
    }

    .content-row-img {
      left: 0;
      right: inherit;
    }

    .content-row-bg {
      right: 0;
      left: inherit;
    }

    @include media(lg) {
      .content-row-text {
        >div {
          max-width: 550px;
          margin: 0 auto;
        }
      }
    };


    &.smooth {
      .content-row-bg {
        transform: rotateY(180deg);
      }
    }
  }

  .content-row-text {
    width: 100%;
    margin-top: $resp-img-size;

    @include media(lg) {
      width: 50%;
      margin-top: 0;
      padding: 0 30px;

      >div {
        max-width: 550px;
      }
    };
  }

  &-testimonial {
    padding: 40px 0;
    font-weight: 300;

    @include media(md) {
      padding: 130px 0;
      min-height: 0;
    };
  }

  .testimonial-author {
    font-size: 16px;
    display: block;
    margin-top: 30px;

    .name {
      font-weight: 700;
    }
  }

  &.strict {
    &.dark-blue {
      background: $dark-blue;
      color: $base-white;
    }

    &.light-grey {
      background: $bg-slide;
    }
  }

  &.smooth {
    &.dark-blue {
      background: $dark-blue;

      .content-row-bg {
        height: 400px;
        top: 0;
        @include gradient-vertical($dark-blue, 30%, rgba(0,42,54,0.0));

        @include media(lg) {
          height: 100%;
          @include gradient-horizontal($dark-blue, 30%, rgba(0,42,54,0.1));
        };

      }
      color: $base-white;
    }

    &.light-grey {
      background: $bg-slide;

      .content-row-bg {
        height: 400px;
        top: 0;
        @include gradient-vertical($bg-slide, 30%, rgba(0,42,54,0.0));

        @include media(lg) {
          height: 100%;
          @include gradient-horizontal($bg-slide, 30%, rgba(0,42,54,0.1));
        };

      }
    }

  }

  &:after {
    content: "";
    display: table;
    clear: both;
  }

  &-map {
    padding: 0;
    height: 300px;

    @include media(lg) {
      height: 600px;
    };

    .map-container {
      height: 100%;
      padding: 0 !important;
      position: relative;

      >div {
        max-width: 100% !important;
      }
    }
  }

  &-webcam {

    .content-row-img {
      background-size: contain !important;
      background-repeat: no-repeat !important;
    }
  }
}

.heading-img {

  img {
    margin-top: -15px;
    float: right;
  }
}

.relative-anchor {
  position: relative;
  top: -60px;
}

.gallery {
  width: 100%;
  border-bottom: 1px solid $base-white;
  border-top: 1px solid $base-white;

  @media all and (min-width: 1300px) {
    height: 600px;
  };

  .mp-item {
    float: left;
    background-size: cover !important;
    background-position: 50% 50% !important;
    display: inline-block;
    opacity: 1;
    position: relative;
    @include transition(150ms);

    &:hover, &:focus {
      outline: none;
      opacity: 0.8;
      text-decoration: none;
    }
  }

  .photo-0 {
    width: 100%;
    height: 200px;
    border-bottom: 1px solid $base-white;

    @media all and (min-width: 480px) {
      height: 300px;
    };

    @include media(md) {
      height: 300px;
      width: 50%;
      border-right: 1px solid $base-white;
    };

    @include media(xl) {
      height: 600px;
      width: 50%;
    };

    @media all and (min-width: 1300px) {
      height: 100%;
      width: 50%;
      border-bottom: 0
    };
  }

  .photo-1 {
    width: 100%;
    height: 200px;
    border-bottom: 1px solid $base-white;

    @media all and (min-width: 480px) {
      height: 300px;
    };

    @include media(md) {
      height: 300px;
      width: 50%;
    };

    @include media(xl) {
      height: 300px;
      width: 50%;
    };

    @media all and (min-width: 1300px) {
      width: 25%;
      height: 50%;
      border-right: 1px solid $base-white;
    };


  }

  .photo-2 {
    width: 100%;
    height: 200px;
    border-bottom: 1px solid $base-white;

    @media all and (min-width: 480px) {
      height: 300px;
    };

    @include media(md) {
      height: 300px;
      width: 50%;
      border-right: 1px solid $base-white;
    };

    @include media(xl) {
      height: 300px;
      width: 50%;
      border-right: 0;
    };

    @media all and (min-width: 1300px) {
      width: 25%;
      height: 50%;
    };
  }

  .photo-3 {
    width: 100%;
    height: 200px;
    border-bottom: 1px solid $base-white;

    @media all and (min-width: 480px) {
      height: 300px;
    };

    @include media(md) {
      height: 300px;
      width: 50%;
    };

    @include media(xl) {
      height: 300px;
      width: 50%;
      border-right: 1px solid $base-white;
      border-bottom: 0;
    };

    @media all and (min-width: 1300px) {
      width: 25%;
      height: 50%;
    };
  }

  .photo-4 {
    width: 100%;
    height: 200px;

    @media all and (min-width: 480px) {
      height: 300px;
    };

    @include media(md) {
      height: 300px;
      width: 100%;
    };

    @include media(xl) {
      height: 300px;
      width: 50%;
    };

    @media all and (min-width: 1300px) {
      width: 25%;
      height: 50%;
    };
  }

  &:after {
    content: "";
    display: table;
    clear: both;
  }

  .gallery-more {
    position: absolute;
    color: $base-white;
    padding: 30px;
    text-align: center;
    font-size: 32px;
    background: rgba(72,175,196,0.7);
    @include transition(200ms);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    &:hover {
      padding-top: 20px;
    }
  }
}
