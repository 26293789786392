@import '_mixins/pux/breakpoints';
@import '_mixins/pux/css-rules';
@import '_variables';
@import "helpers";

.map-points {
  display: none;
}

.map-wrapper {
  overflow: hidden;
  position: relative;
}

#main-map {
  height: 300px;

  @include media(md) {
    height: 600px;
  };
}

#map {
  height: 300px;

  @include media(md) {
    height: 600px;
  };
}

#partner-slideup {
  position: relative;
  z-index: 1;
}

#map-filter {
  position: relative;
  z-index: 2;
}

.partner-info-wrapper {
  position: absolute;
  top: 0;
  background: rgba(0,42,54, 0.8);
  padding: 25px 0;
  @include transition(200ms);
  width: 100%;
  color: $base-white;
  font-size: 16px;

  .partner-name {
    font-size: 20px;

    &:hover, &:focus {
      color: $base-white;
    }
  }

  .partner-detailed {
    margin: 10px 0 25px
  }

  .btn:hover, .btn:focus {
    outline: none;
    background: $base-white;
    color: $light-blue;
  }
}

.close-info {
  color: $base-white;
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 30px;
  line-height: 15px;
  cursor: pointer;
  opacity: 0.8;
  @include transition(150ms);


  &:hover, &:focus {
    opacity: 1;
  }
}
