@import '_mixins/pux/breakpoints';
@import '_mixins/pux/css-rules';
@import '_variables';
@import "helpers";

.contact-icon-wrapper {
  display: flex;
  flex-wrap: nowrap;
  align-items: baseline;
  margin-top: 20px;
  color: $base-color;
  font-weight: 400;
  strong {
    display: block;
  }
  a {
    color: $base-color;
  }

  a[href^="mailto:"],
  [data-event="view_email"] {
    color: $light-blue;
  }
  
  &:hover {
    text-decoration: none;
    color: $base-color;
  }

  strong {
    font-weight: 700;
  }

  .text {
    transform: translateY(-40px);
    padding-left: 15px;

    @include media(md) {
      transform: translateY(-50px);
    };
  }


  .contact-icon {
    width: 80px;
    height: 80px;
    flex-shrink: 0;
    border-radius: 50%;

    @include media(md) {
      width: 100px;
      height: 100px;
    };

    background-size: cover !important;
    background-repeat: no-repeat !important;

    &-pin {
      background: url("../img/icon-kontakt-1.png");
      //background: url("../img/icon-kontakt-1.svg");
    }

    &-mail {
      background: url("../img/icon-kontakt-2.png");
      //background: url("../img/icon-kontakt-2.svg");
    }

    &-phone {
      background: url("../img/icon-kontakt-3.png");
      //background: url("../img/icon-kontakt-3.svg");
    }

    &-calendar {
      background: url("../img/icon-kontakt-4.png");
      //background: url("../img/icon-kontakt-4.svg");
    }
  }
}

.contact-item {
  .contact-show {
    display: block !important;
    cursor: pointer;
    color: $light-blue;
  }
  a {
    display: none !important;
  }
  &.open {
    .contact-show {
      display: none !important;
    }
    a {
      display: block !important;
    }
  }
}

.person-widget {
  display: flex;
  flex-wrap: wrap;
  &.person-widget-layout-1 {
    flex-direction: column;
    >div {
      width: auto;
      flex-basis: auto;
    }
    &.person-widget-layout-left {
      align-items: flex-start;
    }
    &.person-widget-layout-right {
      align-items: flex-end;
    }
    &.person-widget-layout-center {
      align-items: center;
    }
  }
  &.person-widget-layout-4 {
    >div {
      @include media(md) {
        width: 50%;
      }
      @include media(xl) {
        width: 33.333%;
      }
      @include media(xxl) {
        width: 25%;
      }
    }
  }
  &.person-widget-layout-3 {
    >div {
      @include media(md) {
        width: 50%;
      }
      @include media(xl) {
        width: 33.333%;
      }
    }
  }
  &.person-widget-layout-2 {
    >div {
      @include media(md) {
        width: 50%;
      }
    }
  }
  &.person-widget-layout-left {
    justify-content: left;
  }
  &.person-widget-layout-right {
    justify-content: right;
  }
  &.person-widget-layout-center {
    justify-content: center;
  }
}

.persons .contact-icon-wrapper {
  height: 200px;
}

.filter-container {
  font-size: 16px;

  @include media(xl) {
    padding-top: 5px;
    padding-left: 90px;
    background-image: url("../img/icon-filter.png");
    background-size: 60px 60px;
    background-position: 0 0;
    background-repeat: no-repeat;
  };
}



.filter-item {
  position: relative;
  margin-bottom: 30px;

  .button {
    padding: 15px 70px 15px 30px;
    background-color: $base-white;
    border-bottom: 1px solid $bg-megamenu;
    cursor: pointer;
    @include transition(150ms);
    position: relative;

    &:after {
      content: "";
      display: inline-block;
      width: 14px;
      height: 8px;
      background-image: url("../img/icon-dropdown-14x8.png");
      top: 24px;
      right: 30px;
      position: absolute;
    }



    &:hover {
      background-color: $bg-megamenu;
    }
  }

  .filter-options {
    opacity: 0;
    pointer-events: none;
    position: absolute;
    @include transition(200ms);
    -webkit-transform: translateY(-20px);
    -moz-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    transform: translateY(-20px);
    background: $base-white;
    width: 100%;
    overflow: auto;
    max-height: 196px;
    z-index: 1;
  }

  &:hover {
    .filter-options {
      opacity: 1;
      pointer-events: all;
      -webkit-transform: translateY(-1px);
      -moz-transform: translateY(-1px);
      -ms-transform: translateY(-1px);
      transform: translateY(-1px);
    }
  }

  input[type=checkbox] + label {
    position: relative;
  }

  input[type=checkbox] + label:before,
  input[type=radio] + label:before {
    content: "";
    display: inline-block;
    width: 12px;
    height: 11px;
    background: $border-grey;
    border-radius: 50%;
    position: relative;
    margin-right: 10px;
    top: 0px;

    @include transition(150ms);
  }


  input[type=checkbox]:checked + label:before,
  input[type=radio]:checked + label:before {
    background: $light-blue;
  }

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  li {


    label {
      margin-bottom: 0;
      @include transition(150ms);
      background: $base-white;
      padding: 10px 30px;
      cursor: pointer;
      width: 100%;

      &:hover {
        background: $bg-megamenu;
      }

      &:last-of-type {
        padding-bottom: 15px;
      }
    }
  }
}

.partner-table {
  font-size: 16px;
  max-width: 100%;
  overflow: auto;

  table {
    min-width: 1190px;
  }

  thead {
    border-bottom: 2px solid $base-white;

    th {
      font-weight: 400;
    }
  }

  table {
    width: 100%;
  }

  td, th {
    padding: 15px;

    &:first-of-type {
      padding-left: 30px;
    }
  }

  tr:nth-of-type(even) {
    background: #fafbfb;
  }
}


.narrow-form-wrapper {
  max-width: 600px;
  margin: 0 auto;

  .button-wrapper {
    //text-align: center;
  }

  input[type='text'], textarea {
    border: 1px solid $border-grey;
    border-radius: 0;
    background: $border-grey;
    @include transition(200ms);

    &:focus {
      outline: 0;
      border: 1px solid $light-blue;
      background: $base-white;
    }
  }

  textarea {
    height: 100px;
  }

  select.form-control {
    border-radius: 0;
    background: $border-grey;
    border: 1px solid $border-grey;
  }
}

.narrow-form-wrapper.career-form {
  max-width: 900px;
  font-size: 16px;
  padding-bottom: 70px;

}

.narrow-form-wrapper {
  h4 {
    margin: 40px 0 0;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
  }
  label {
    margin: 0.5rem 0;
  
  }
  .ExplanationText {
    font-size: 15px;
    margin-top: 5px;
  }
} 
.agree-checkbox {
  padding-top: 20px;

  >label {
    margin-top: 0;
    display: inline-flex;

    label {
      margin: 4px 0 0 0;
      transform: translateY(-5px);

    }
  }
}

.submit-wrap {
  text-align: center;
  padding: 30px 0;
  input[type="submit"] {
    margin-bottom: 1rem;
  
  }
}

.uploader-upload {
    input[type=file] {
        display: none;
    }
}

.fake-upload-wrapper {

 label {
   margin-bottom: 150px;
   position: relative;
   width: 100%;
 }

.fake-upload {
    height: 115px;
    width: 100%;
    position: absolute;
    top: 35px;
    left: 0;

    &__text {
        width: 100%;
        height: 50px;
        overflow: hidden;
        display: block;
        border: 1px solid $border-grey;
        background: $border-grey;
        padding: 9px 20px 13px;
        line-height: 30px;
        text-align: center;
        color: $light-blue;
        margin-top: 30px;
    }

    &__button {
        width: 100%;
        min-height: 50px;
        cursor: pointer;
        padding: 14px 25px 12px 15px;

        margin-bottom: 15px;
        text-align: center;
        background: $light-blue;
        color: $base-white;
        line-height: 22px;
        top: 17px;
        position: relative;
        border: 1px solid $light-blue;
        @include transition(150ms);
        line-height: 18px;

        &:hover {
          background: $base-white;
          color: $light-blue;
        }
    }
  }


  @include media(md) {

      label {
          margin-bottom: 80px;
      }

      .fake-upload {
          height: 50px;

          &__text {
              width: auto;
              margin-top: 0;
          }

          &__button {
              width: 50%;
              margin-left: 0;
              float: right;
              margin-bottom: 0;
              height: 50px;
              position: static;
          }
      }
  }
}

.EditingFormErrorLabel, .ErrorLabel {
  color: #bc0000;
  font-size: 13px;
}
.ErrorLabel {
  text-align: center;
  width: 100%;
  display: block;
  margin-top: 15px;
}

 .gallery-thumbs {
   display: none;

   @include media(lg) {
     display: block;
     width: 50%;
     height: 100%;
     position: absolute;
     text-align: center;
     padding-top: 500px;
     font-size: 0;

     .thumb {
       width: 120px;
       height: 75px;
       background-size: cover !important;
       z-index: 2;
       position: relative;
       display: inline-block;
       margin: 0 5px;
       box-shadow: 1px 2px 2px rgba(45,45,45,.5);
       opacity: 0.8;
       @include transition(150ms);


       &:hover, &:focus {
         opacity: 1;
       }

       .blue-overlay {
          position: absolute;
          width: 100%;
          height: 100%;
          background: $light-blue;
          opacity: 0.6;
       }
     }
   };
 }

 .phones-wrap {
   min-height: 116px;
   display: flex;
   flex-flow: column;
   justify-content: center;

   a.phone-link {
     margin-bottom: 5px;
   }

   &.full {
     height: 100%;
   }
 }

 .contact-item:not(.open) {
  .text.doublemail {
    a {
      word-break: break-all;
      display: block;
      margin: 5px 0;
    }
  }
 }



 .contact-icon-wrapper .text.triplemail {
   top: 9%;

   @include media(md) {
     top: 20%;
   };

   @include media(xxl) {
     top: 18%;
   };
 }

 label{
  &.required{
    &:after{
        content:"*";
        display: inline-block;
        vertical-align: 5px;
        margin: 0 2px 0 0;
        color:$light-blue;
        font-size: 15px;
    }
  }
}
