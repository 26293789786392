@import '_mixins/pux/breakpoints';
@import '_mixins/pux/css-rules';
@import '_variables';
@import "helpers";

.header-block {
  height: 400px;
  background-size: cover !important;
  background-position: 50% 50% !important;
  position: relative;

  @include media(md) {
    height: 670px;
  };

  @include media(xxl) {
    height: 570px;
  };

  .agrotec-container {
    height: 100%;
  }

  &-bg {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,.5);
    top: 0;

    @include media(lg) {
      width: 50%;
    };
  }

  &-text {
    @include vertical-pos(50%);
      color: $base-white;
      width: 100%;
      padding: 0;

      @include media(lg) {
        width: 50%;
        max-width: 550px;
      };

    h1 {
      font-size: 28px;
      margin-left: 30px 0;

      @include media(md) {
        font-size: 66px;
        margin: 0 0 60px 0;
      };
    }
  }

  &.text-left {

    .header-block-bg {
      left: 0;
    }
  }

  &.text-right {

    .header-block-text {
      float: right;

      @include media(lg) {
        padding-left: 40px;
      };
    }

    .header-block-bg {
      right: 0;
    }
  }

  .slick.block-arrows .slick-arrow {
    border-top: 0;
    border-bottom: 0;
  }
}

@media all and (max-width: 1680px) {
  .header-block-text {
    padding: 0 40px;
  }
}
