@import '_mixins/pux/breakpoints';
@import '_mixins/pux/css-rules';
@import '_variables';
@import "helpers";

$resp-news-img-size: 320px;

.news {
  width: 100%;
  position: relative;
  padding: 40px 0;

  @include media(lg) {
    padding: 55px 0;
    min-height: 500px;
  };

  h2 {
    color: $light-blue;
    margin-bottom: 30px;
  }

  &.slick-slide {
    a, a:hover, a:focus {
      //color: $light-blue;
    }
  }

  &-photo, &-bg {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;

    @include media(lg) {
      width: 50%;
      height: 100%;
    };
  }

  &-photo {
    left: 0;
    top: 0;
    background-size: cover !important;
    background-position: 50% 50% !important;
    z-index: 1;

    height: $resp-news-img-size;

    @include media(lg) {
      height: 100%;
    };
  }

  &-bg {
    right: 0;
    top: 0;
    background: $bg-slide;
  }

  &-text {
    margin-top: $resp-news-img-size;
    padding: 0 50px;

    @include media(lg) {
      display: inline-block;
      width: 50%;
      float: right;
      margin-top: 0;

      >div {
        max-width: 550px;
        margin: 0 auto;
      }
    };
  }

  .news-info {
    font-size: 14px;
    color: #828686;
    margin-bottom: 30px;
  }

  .info-separator {
    margin: 0 6px;
  }

  .read-more {
    display: inline-block;
    margin-top: 30px;
  }

  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

.heading-link {
  h2 {
    @include transition(200ms);
  }

  &:hover, &:focus {
    outline: 0;
    text-decoration: none;

    h2 {
      color: $base-color;
    }
  }
}

.product-text .date {
  display: block;
  margin: -10px 0 10px 0;
}

.news.agrotec-grid {
  padding-bottom: 0;

  .agrotec-grid-item {
    height: 600px;
  }
}
