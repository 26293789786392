@import '_mixins/pux/breakpoints';
@import '_mixins/pux/css-rules';
@import '_variables';
@import "helpers";

.products {

  .product {
    height: 520px;
    border-right: 1px solid $border-grey;
    color: $base-color;
    display: inline-block;
    background: $base-white;
    position: relative;

    .product-img-wrapper {
      height: 270px;
      overflow: hidden;
    }

    .product-img {
      background-size: cover !important;
      background-position: 50% 50% !important;
      height: 270px;
      @include transition(300ms);
      -webkit-backface-visibility: hidden;
      -ms-transform: translateZ(0); /* IE 9 */
      -webkit-transform: translateZ(0); /* Chrome, Safari, Opera */
      transform: translateZ(0);
    }

    .product-text {
      padding: 30px;
      font-size: 16px;
      background-size: 14px 14px !important;
      background-position: 90% 90% !important;
      background-repeat: no-repeat !important;

      @include media(md) {
        padding: 50px;
      };

      .product-name {
        font-weight: 700;
        color: $light-blue;
        position: relative;
        display: block;
        margin-bottom: 15px;
        font-size: 20px;
        @include transition(200ms);
      }
    }

    &-icon {
      position: absolute;
      right: 40px;
      bottom: 40px;
      width: 14px;
      height: 14px;
      font-size: 14px;
      &:before {
        color: $light-blue;
        @include transition(200ms);
      }
    }

    &:hover, &:focus {
      outline: 0;
      text-decoration: none;
      .product-name {
        color: $dark-blue;
      }

      .product-img {
        -webkit-transform: translateZ(0) scale(1.05);
        transform: translateZ(0) scale(1.05);
      }

      .product-icon {
        &:before {
          color: $base-color;
        }
      }
    }
  }
}
