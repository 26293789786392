@import '_mixins/pux/breakpoints';
@import '_mixins/pux/css-rules';
@import '_variables';
@import "helpers";

.video-wrapper {
  position: relative;
  background-size: cover !important;
  overflow: hidden;

  @include media(xl) {
    min-height: inherit !important;
  };


  .bg {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    background: rgba(0,0,0,0.7);

    @include media(xl) {
      width: 50%;
    };
  }

  #video-text-wrapper {
    position: relative;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    margin-bottom: 40px;

    @include media(xl) {
      position: absolute;
      padding-bottom: 0;
    };
  }

  .video-text {
    color: $base-white;
    position: relative;
    z-index: 1;
    width: 100%;
    padding-top: 20px;

    @include media(xl) {
      width: 50%;
      padding-top: 7%;
      padding-right: 10%;
    };
  }
}

#video {
  display: none;

  @include media(xl) {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    pointer-events: none;
    position: relative;
    //padding-bottom: 52.75%;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    z-index: 1;

    iframe, object, embed {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  };
}

#btn-play {
  display: inline-block;
  position: relative;
  width: 100px;
  height: 100px;
  background: rgba(72,175,196, 0.6);
  left: 50%;
  margin-left: -50px;
  z-index: 3;
  border-radius: 50%;
  cursor: pointer;
  @include transition(100ms);
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  transform: translateZ(0);

  @include media(xl) {
    position: absolute;
    top: 50%;
    margin-top: -50px;
  };

  &:before {
    content: "";
    width: 19px;
    height: 24px;
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -7px;
    margin-top: -11px;
    background: url("../img/play.png")
  }

  &:hover, &:focus {
    outline: 0;
    background: rgba(72,175,196, .85);
  }
}
