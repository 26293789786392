@media print {
  header,
  footer, .cookie-law-approval, .btn, .gallery-thumbs, .arrow-scroll, .detail-form-wrapper, #related-products, .copyright, .contacts, .after-header, .corner {
    display:none;
  }

  .car-detail-image, .contact-icon {
    -webkit-print-color-adjust:exact;
  }
}
