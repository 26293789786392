@import '_mixins/pux/breakpoints';
@import '_variables';

.margin-0 {
  margin: 0;
}

.margin-bottom-20 {
  margin-bottom: 20px;
}

.margin-bottom-30 {
  margin-bottom: 15px;

  @include media(md) {
    margin-bottom: 15px;
  };
}

.margin-bottom-50 {
  margin-bottom: 25px;

  @include media(md) {
    margin-bottom: 25px;
  };
}

.margin-bottom-40 {
  margin-bottom: 20px;
}

.margin-bottom-60 {
  margin-bottom: 30px;
}

.margin-bottom-70 {
  margin-bottom: 35px;
}

.margin-bottom-120 {
  margin-bottom: 60px;
}

.margin-bottom-30-sm-down {
  margin-bottom: 30px;

  @include media(md) {
    margin-bottom: 0;
  };
}

.margin-bottom-0 {
  margin-bottom: 0;
}

.margin-top-negative-30 {
  margin-top: -30px;
}

.margin-top-0{
  margin-top: 0;
}

.margin-top-30 {
  margin-top: 30px;
}

.margin-top-40 {
  margin-top: 20px
}

.margin-top-50 {
  margin-top: 25px
}

.margin-top-60 {
  margin-top: 30px
}

.padding-right-90-md-up {
  @include media(md){
    padding-right: 45px;
  };
}

.no-padding-top-sm-down {
  @media all and (max-width: 768px) {
    padding-top: 0;
  }
}

.align-left {
  text-align: left;
}

.align-right {
  text-align: right;
}

.font-size-26 {
  font-size: 13px;

  @include media(md) {
    font-size: 26px;
  };
}

.font-size-35 {
  @include media(md) {
    font-size: 35px !important;
  };
}

.font-size-20 {
  font-size: 20px;
}

.font-size-50 {
  font-size: 50px !important;
}

.light-weight {
  font-weight: 300;
}

.semi-weight {
  font-weight: 600;
}

.align-right-on-xs {
  text-align: right;

  @include media(md) {
    text-align: left;
  };
}

.center-content {
  text-align: center;
}

.text-light {
  font-weight: 300;

  strong {
    font-weight: 700;
  }
}

.col-xxl-3 {
  @include media(xxl) {
    width: 25%;
  };
}

.hidden {
  display: none;
}
